import React, { useEffect, useRef, useState } from "react"
import Helmet from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import SVGRiseLogo from '../images/rise-logo.inline.svg'
import SVGBalloonTether from '../images/balloon-tether.inline.svg'
import SVGFootHills from '../images/foothills2.inline.svg'
import SVGFGTrees from '../images/trees-foreground.inline.svg'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
import { Spin as Hamburger } from 'hamburger-react'
import '../scss/styles.scss'

// Register GSAP ScrollTrigger
gsap.registerPlugin(ScrollTrigger); 

// data
const links = [
  {
    text: "About",
    url: "#about",
    target: '',
    rel: ''
  },
  {
    text: "Rates & Hours",
    url: "#rates",
    target: '',
    rel: ''
  },
  {
    text: "Contact",
    url: "#contact",
    target: '',
    rel: ''
  },
  {
    text: "#RISEAK",
    url: "https://instagram.com/riseak",
    target: '_blank',
    rel: "me"
  },
]

const IndexPage = () => {
  const [isOpen, setOpen] = useState(false)
  const refLogo = useRef(null)
  const refTether = useRef(null)
  const refCloud01 = useRef(null)
  const refCloud02 = useRef(null)
  const refCloud03 = useRef(null)
  const refIntro = useRef(null)
  const refPoint01 = useRef(null)
  const refScenery = useRef(null)
  const refHeader = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const dh = Math.max(
        document.documentElement["clientHeight"],
        document.body["scrollHeight"],
        document.documentElement["scrollHeight"],
        document.body["offsetHeight"],
        document.documentElement["offsetHeight"]
      )

      // Cloud motion
      gsap.set(refCloud01.current, {left: '-5%'})
      gsap.to(refCloud01.current, {
        duration: gsap.utils.random(5, 10),
        x: 0 - refCloud01.current.offsetWidth - refCloud01.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud01.current, { left: 0 })
          gsap.fromTo(refCloud01.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud01.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      gsap.set(refCloud02.current, {left: '30%'}) 
      gsap.to(refCloud02.current, {
        duration: gsap.utils.random(10, 30),
        x: 0 - refCloud02.current.offsetWidth - refCloud02.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud02.current, { left: 0 })
          gsap.fromTo(refCloud02.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud02.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      gsap.set(refCloud03.current, {left: '55%'}) 
      gsap.to(refCloud03.current, {
        duration: gsap.utils.random(10, 30),
        x: 0 - refCloud03.current.offsetWidth - refCloud03.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud03.current, { left: 0 })
          gsap.fromTo(refCloud03.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud03.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      // 
      const sceneryStart = 500 + refIntro.current.offsetHeight + refPoint01.current.offsetHeight + refHeader.current.offsetHeight
      refScenery.current.style.top = `${sceneryStart}px`
      refScenery.current.style.height = `${dh - sceneryStart}px`
    
      
    }
  }, [])

  return (
    <>
      <Helmet title="RISE AK" />
      <header className="riseHeader" ref={refHeader}>
        <div className="clouds">
          <div ref={refCloud02} className="cloud02 cloudWrapper"><StaticImage src="../images/cloud02.png" alt="cloud" /></div>
          <div ref={refCloud03} className="cloud03 cloudWrapper"><StaticImage src="../images/cloud03.png" alt="cloud" /></div>
          <div ref={refCloud01} className="cloud01 cloudWrapper"><StaticImage src="../images/cloud01.png" alt="cloud" /></div>
        </div>
        <div className="offCanvas" style={{ top: `${ isOpen ? '-2%' : '-200%'}` }}>
          <ul className="OCNavList">
            {links.map((link, i) => {
              return (
                <li key={`oc-nav-list-${i}`}>
                  <a href={link.url} target={link.target} rel={link.rel} className="navLink">{link.text}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <nav className="riseNav">
          <ul className="navList">
            {links.map((link, i) => {
              return (
                <li key={`nav-list-${i}`}>
                  <a href={link.url} target={link.target} rel={link.rel} className="navLink">{link.text}</a>
                </li>
              )
            })}
          </ul>
          <div className="burgerMenu">
            <Hamburger toggled={isOpen} toggle={setOpen} rounded hideOutline={true} size={48} />
          </div>
          <div className="logoWrapper" ref={refLogo}>
            <div ref={refTether} style={{position: 'absolute', left: '0', top: '0', width: '100%'}}>
              <SVGBalloonTether />
            </div>
            <SVGRiseLogo />
          </div>
        </nav>
      </header>
      <div className="scenery" ref={refScenery}>
        <div className="denali" />
        <div className="foothills">
          <SVGFootHills />
        </div>
      </div>
      <main>
        <div className="container">
          <div className="intro container container-sm" ref={refIntro}>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
              dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
              lobortis nisl ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className="balloonPoints">
              <div className="point01 bpWrapper" ref={refPoint01}>
                <div className="bpText">
                  <h3>Elevation & Time</h3>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-updown.svg" alt="UpDown icon" />
                </div>
              </div>
              <div className="point02 bpWrapper ralign">
                <div className="bpText">
                  <h3>Photography</h3>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-camera.svg" alt="Camera icon" />
                </div>
              </div>
              <div className="point03 bpWrapper">
                <div className="bpText">
                  <h3>Information & Stuff</h3>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-map.svg" alt="Map icon" />
                </div>
              </div>
              <div className="point04 bpWrapper ralign">
                <div className="bpText">
                  <h3>Photography</h3>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-camera.svg" alt="Camera icon" />
                </div>
              </div>
              <div className="point05 bpWrapper">
                <div className="bpText">
                  <h3>Information & Stuff</h3>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-map.svg" alt="Map icon" />
                </div>
              </div>
          </div>
        </div>
      </main>
      <div className="instagram">
        <div className="igTrees">
          <SVGFGTrees />
        </div>
      </div>
    </>
  )
}

export default IndexPage
